<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h2 class="text-center">Wordt gebracht - {{ bu }}</h2>
    <Loading v-if="loading" />
    <div v-else>
      <Tabs>
        <Tab v-for="(buyer, key) in brought_orders" :key="key" :title="key">
          <table :class="`w-100 table--default table__border--${bu}`">
            <thead :class="`table__head--${bu}`">
              <tr>
                <th class="table__head--text">Item</th>
                <th class="table__head--text">Omschrijving</th>
                <th class="table__head--text" style="min-width: 100px">
                  Verwacht
                </th>
                <th class="table__head--text">Betaald</th>
                <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text">
                  Leverancier
                </th>
                <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text">
                  Locatie
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) in buyer"
                :key="key"
                class="table__row--color"
              >
                <td class="table__cell--default">
                  <ImageHover :itemnummer="item.itemnummer" :bu="bu" />
                </td>
                <td class="table__cell--default">{{ item.voertuig }}</td>
                <td class="table__cell--default">{{ item.aanleverdatum }}</td>
                <td class="table__cell--default">
                  <div v-if="item.betaald == 1">
                    <span v-b-tooltip.hover :title="item.betaaldatum"
                      ><i class="fas fa-euro-sign"></i
                    ></span>
                  </div>
                  <div v-else>
                    <span v-b-tooltip.hover title="Nog niet betaald"
                      ><i class="fab fa-creative-commons-nc-eu"></i
                    ></span>
                  </div>
                </td>
                <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">
                  {{ item.bedrijf }}
                </td>
                <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">
                  {{ item.locatie }}
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";

import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: { Loading, ImageHover, Tab, Tabs },
  data: () => ({
    loading: null,
    brought_orders: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`brought-orders/${bu}`, "GET").then(({ brought_orders }) => {
        this.brought_orders = brought_orders;
        this.loading = false;
      });
    },

    
  },
};
</script>
